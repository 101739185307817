// React
import { FunctionComponent, useEffect, useState } from "react";
// Library
import { SearchableTable, Title } from "@fyrstain/fhir-front-library";
// Components
import JupiterPage from "../../components/JupiterPage/JupiterPage";
// Styles
import styles from "./Home.module.css";
import "./Home.module.css";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  Modal,
  Offcanvas,
  Row,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, faEye } from "@fortawesome/free-solid-svg-icons";
import Client from "fhir-kit-client";
import { Bundle, Composition, DiagnosticReport, Parameters } from "fhir/r4";
import CodeEditor from "../../components/CodeEditor";
import { Buffer } from "buffer";
import i18n from "i18next";

interface card {
  hasEBV: boolean;
  isImmunoD: boolean;
  isAtRisk: boolean;
}

const Home: FunctionComponent = () => {
  const [patientCount, setPatientCount] = useState(0);
  const [opposedCount, set0pposedCount] = useState(0);
  const [sickCount, setSickCount] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [diasbleinput, setDisableInput] = useState(true);
  const [modalShowRight, setModalShowRight] = useState(false);
  const [modalShowBottom, setModalShowBottom] = useState(false);
  const [cardShow, setCardShow] = useState(false);
  const [annuaireShow, setAnnuaireShow] = useState(false);
  const [cardContent, setCardContent] = useState({
    hasEBV: false,
    isImmunoD: false,
    isAtRisk: false,
  });
  const [contentToMap, setContentToMap] = useState("");
  const [structureMap, setStructureMap] = useState("");
  const [targetId, setTargetId] = useState("574687583");
  const [targetSystemId, setTargetSystemId] = useState(
    "urn:oid:2.16.840.1.113883.2.4.6.3"
  );
  const [targetUrl, setTargetUrl] = useState("https://hapi.fhir.org/baseR4");
  const [IPSId, setIPSId] = useState("");

  const fhirClient = new Client({
    baseUrl: process.env.REACT_APP_FHIR_URL ?? "fhir",
  });

  const fhirExportClient = new Client({
    baseUrl: process.env.REACT_APP_EXPORT_URL ?? "fhir",
  });

  const fhirIPSClient = new Client({
    baseUrl: process.env.REACT_APP_IPS_URL ?? "fhir",
  });

  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);
  const handleCloseCard = () => {
    setCardShow(false);
  };

  const handleCloseAnnuaire = () => {
    setAnnuaireShow(false);
  };

  useEffect(() => {
    load();
  }, []);

  function importJSON() {
    let parameterMap: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "consentement1",
          part: [
            {
              name: "content",
              valueBase64Binary: Buffer.from(contentToMap).toString("base64"),
            },
            {
              name: "structureMap",
              valueUrl: structureMap,
            },
          ],
        },
      ],
    };

    let parameter: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "url",
          valueUrl: process.env.REACT_APP_FHIR_URL,
        },
        {
          name: "toMap",
          resource: parameterMap,
        },
      ],
    };
    fhirExportClient
      .operation({
        resourceType: "Patient",
        name: "extractdata",
        method: "POST",
        input: parameter,
      })
      .then(() => {
        handleClose();
        load();
      })
      .catch(() => "");
  }

  function exportResearch() {
    let parameter: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "source",
          valueUrl: process.env.REACT_APP_FHIR_URL,
        },
        {
          name: "target",
          valueUrl: process.env.REACT_APP_RESEARCH_URL,
        },
        {
          name: "ipp-system",
          valueString: "https://www.ghdc.be",
        },
      ],
    };
    fhirExportClient
      .operation({
        name: "export",
        method: "POST",
        input: parameter,
      })
      .then(() => {
        load();
      })
      .catch(() => "");
  }

  function generateIPS() {
    let parameter: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "identifierParam",
          valueCoding: {
            system: targetSystemId,
            code: targetId,
          },
        },
        {
          name: "dataEndpoint",
          resource: {
            resourceType: "Endpoint",
            address: targetUrl,
            connectionType: {},
            status: "active",
            payloadType: [],
          },
        },
      ],
    };
    fhirIPSClient
      .operation({
        resourceType: "Patient",
        name: "summary-import",
        method: "POST",
        input: parameter,
      })
      .then((response) => {
        if (response.entry && response.entry[0] && response.entry[0].resource) {
          let ref = (response.entry[0].resource as Composition).subject
            ?.reference;
          response.entry[0].resource.subject.reference = targetUrl + "/" + ref;
        }
        fhirClient
          .create({
            resourceType: "Bundle",
            body: response,
          })
          .then((response) => {
            setIPSId(response.id);
          })
          .catch(() => "");
      });
  }

  function calculateRisk() {
    let atRiskV: boolean = false;
    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        // {
        //   name: "content",
        //   valueString: sourceCode,
        // },
        {
          name: "dataEndpoint",
          resource: {
            resourceType: "Endpoint",
            status: "active",
            connectionType: {
              system:
                "http://terminology.hl7.org/CodeSystem/endpoint-connection-type",
              code: "hl7-fhir-rest",
            },
            payloadType: [
              {
                coding: [
                  {
                    system:
                      "http://terminology.hl7.org/CodeSystem/endpoint-connection-type",
                    code: "hl7-fhir-rest",
                    display: "HL7 FHIR",
                  },
                ],
              },
            ],
            address: process.env.REACT_APP_FHIR_URL ?? "",
            header: ["Content-Type: application/json"],
          },
        },
        {
          name: "terminologyEndpoint",
          resource: {
            resourceType: "Endpoint",
            status: "active",
            connectionType: {
              system:
                "http://terminology.hl7.org/CodeSystem/endpoint-connection-type",
              code: "hl7-fhir-rest",
            },
            payloadType: [
              {
                coding: [
                  {
                    system:
                      "http://terminology.hl7.org/CodeSystem/endpoint-connection-type",
                    code: "hl7-fhir-rest",
                    display: "HL7 FHIR",
                  },
                ],
              },
            ],
            address: process.env.REACT_APP_FHIR_URL ?? "",
            header: ["Content-Type: application/json"],
          },
        },
        {
          name: "contentEndpoint",
          resource: {
            resourceType: "Endpoint",
            status: "active",
            connectionType: {
              system:
                "http://terminology.hl7.org/CodeSystem/endpoint-connection-type",
              code: "hl7-fhir-rest",
            },
            payloadType: [
              {
                coding: [
                  {
                    system:
                      "http://terminology.hl7.org/CodeSystem/endpoint-connection-type",
                    code: "hl7-fhir-rest",
                    display: "HL7 FHIR",
                  },
                ],
              },
            ],
            address: process.env.REACT_APP_FHIR_URL ?? "",
            header: ["Content-Type: application/json"],
          },
        },
        {
          name: "subject",
          valueString: targetId,
        },
      ],
    };
    fhirClient
      .operation({
        resourceType: "Library",
        name: "$evaluate",
        id: "ImmunoAtRisk",
        method: "POST",
        input: parameters,
      })
      .then((response) => {
        atRiskV =
          (response as Parameters).parameter?.filter(
            (param) => param.name == "atRisk"
          )[0].valueBoolean ?? false;
        if (atRiskV) {
          const diagReport: DiagnosticReport = {
            resourceType: "DiagnosticReport",
            code: {
              coding: [
                {
                  code: "281694009",
                  system: "http://snomed.info/sct",
                  display: "At risk - finding",
                },
              ],
            },
            subject: {
              identifier: {
                system: "https://www.ghdc.be",
                value: targetId,
              },
            },
            status: "final",
          };
          fhirClient
            .create({
              resourceType: "DiagnosticReport",
              body: diagReport,
            })
            .then(() => load());
        }
        setCardContent({
          hasEBV:
            (response as Parameters).parameter?.filter(
              (param) => param.name == "atRisk"
            )[0].valueBoolean ?? false,
          isImmunoD:
            (response as Parameters).parameter?.filter(
              (param) => param.name == "atRisk"
            )[0].valueBoolean ?? false,
          isAtRisk: atRiskV,
        });
      });
    setCardShow(true);
  }

  function importConsent() {
    setContentToMap(
      JSON.stringify(
        {
          identifiant_du_patient: "XXXXXXXXXXXX",
          nom: "OVHDemo",
          prenom: "Kate",
          telecom_at_home: "0123456789",
          telecom_mobile: "0123456789",
          date_de_naissance: "1955-04-14",
          identifiant_patient_hopital: "YYYYYY",
          adresse: "",
          langue_du_patient: "F",
          genre_du_patient: "male",
          statut_marital_du_patient: "",
          age: "67",
          categorie_age: "3",
          medecin: "MEDECIN Hippocrates",
          numero_identification_medecin_INAMI: "XXXXXXXXXXXXXX",
          specialite: "medecine interne",
          refus_de_participation: "",
        },
        null,
        "\t"
      )
    );
    setStructureMap(
      "https://www.ghdc.be/StructureMap/StructureMap-Consent-JSON"
    );
    handleShow();
  }

  function importPatientData() {
    setContentToMap(
      JSON.stringify(
        {
          identifiant_du_patient: "XXXXXXXXXXXX",
          nom: "OVHDemo",
          prenom: "Kate",
          date_de_naissance: "1955-04-14",
          identifiant_patient_hopital: "YYYYYY",
          probleme_de_sante: [
            "90560007",
            "840303004",
            "38341003",
            "370992007",
            "48694002",
            "80146002",
          ],
          procedures: ["90199006", "386792000", "44946007"],
          allergies: ["102261002"],
        },
        null,
        "\t"
      )
    );
    setStructureMap(
      "https://www.ghdc.be/StructureMap/StructureMap-FicheSante-FHIR"
    );
    handleShow();
  }

  function importPatientAtRiskData() {
    setContentToMap(
      JSON.stringify(
        {
          identifiant_du_patient: "XXXXXXXXXXXX",
          nom: "OVHDemo",
          prenom: "Kate",
          date_de_naissance: "1955-04-14",
          identifiant_patient_hopital: "YYYYYY",
          probleme_de_sante: [
            "90560007",
            "840303004",
            "38341003",
            "370992007",
            "86553008",
            "240530001",
          ],
          procedures: ["90199006", "386792000", "44946007"],
          allergies: ["102261002"],
        },
        null,
        "\t"
      )
    );
    setStructureMap(
      "https://www.ghdc.be/StructureMap/StructureMap-FicheSante-FHIR"
    );
    handleShow();
  }

  async function load() {

    fhirClient.search({
      resourceType: "Patient",
      searchParams: {
        _count: 1111111,
        _elements: "id"
      }
    }).then(response => setPatientCount((response as Bundle).total ?? 0));

    fhirClient
      .search({
        resourceType: "Patient",
        searchParams: {
          _tag: "HRESCH",
        },
      })
      .then((response) => set0pposedCount((response as Bundle).total ?? 0));

    fhirClient.search({
      resourceType: "DiagnosticReport",
      searchParams: {
        _count: 1111111,
        _elements: "id"
      }
    }).then(response => setSickCount((response as Bundle).total ?? 0));

  }

  return (
    <JupiterPage loading={false} fitFooter={false} needsLogin={false}>
      <>
        <Offcanvas show={modalShow} className="bigOffcanvas">
          <Offcanvas.Header closeButton onClick={() => handleClose()}>
            <Offcanvas.Title>{i18n.t("title.jsonexport")}</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <CodeEditor
              content={contentToMap}
              setContent={setContentToMap}
            />

            <Button variant="primary" onClick={() => importJSON()}>
              {i18n.t("button.platformfhirimport")}
            </Button>
          </Offcanvas.Body>
        </Offcanvas>
        <Offcanvas
          show={modalShowRight}
          className="bigOffcanvas"
          placement="end"
        >
          <Offcanvas.Header
            closeButton
            onClick={() => {
              setIPSId("");
              setModalShowRight(false);
            }}
          >
            {diasbleinput && (
              <Offcanvas.Title>
                {i18n.t("title.importpatientsummary")}
              </Offcanvas.Title>
            )}
            {!diasbleinput && (
              <Offcanvas.Title>
                {i18n.t("title.exportpatientsummary")}
              </Offcanvas.Title>
            )}
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label className={styles.formLabel}>
                  <h5>{i18n.t("label.identifiantpatient")}</h5>
                </Form.Label>
                <Form.Control
                  type="text"
                  autoFocus
                  disabled={diasbleinput}
                  value={targetId}
                  onChange={(e) => setTargetId(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label hidden={!diasbleinput} className={styles.formLabel}>
                  <h5>{i18n.t("label.identifiantpatientsystem")}</h5>
                </Form.Label>
                <Form.Control
                  type="text"
                  autoFocus
                  hidden={!diasbleinput}
                  disabled={true}
                  value={diasbleinput ? targetSystemId : "https://www.ghdc.be"}
                  onChange={(e) => setTargetSystemId(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className={styles.formLabel}>
                  <h5>{i18n.t("label.urltragetfhirserver")}</h5>
                </Form.Label>
                <Form.Control
                  type="text"
                  autoFocus
                  disabled={true}
                  value={targetUrl}
                  onChange={(e) => setTargetUrl(e.target.value)}
                />
              </Form.Group>
            </Form>

            <Button variant="primary" onClick={() => generateIPS()}>
              {i18n.t("button.generateips")}
            </Button>
            {IPSId && (
              <FontAwesomeIcon
                icon={faEye}
                size="2x"
                className="actionIcon"
                onClick={() =>
                  window.open(
                    "https://papyrus.ovh.fyrstain.com/BundleDocumentViewer/" +
                    IPSId,
                    "_blank"
                  )
                }
              />
            )}
          </Offcanvas.Body>
        </Offcanvas>
        <Offcanvas
          show={modalShowBottom}
          className="bigOffcanvas"
          placement="bottom"
        >
          <Offcanvas.Header
            closeButton
            onClick={() => setModalShowBottom(false)}
          >
            <Offcanvas.Title>
              {i18n.t("title.patientriskverification")}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label className={styles.formLabel}>
                  <h5>{i18n.t("label.identifiantpatient")}</h5>
                </Form.Label>
                <Form.Control
                  type="text"
                  autoFocus
                  value={targetId}
                  onChange={(e) => setTargetId(e.target.value)}
                />
              </Form.Group>
            </Form>

            <Button variant="primary" onClick={() => calculateRisk()}>
              {i18n.t("button.chekingpatientrisk")}
            </Button>
            {IPSId && (
              <FontAwesomeIcon
                icon={faEye}
                size="2x"
                className="actionIcon"
                onClick={() =>
                  window.open(
                    "https://papyrus.ovh.fyrstain.com/BundleDocumentViewer/" +
                    IPSId,
                    "_blank"
                  )
                }
              />
            )}
          </Offcanvas.Body>
        </Offcanvas>

        <Modal
          show={cardShow}
          onHide={handleCloseCard}
          hide
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h2 className={styles.flexStartAlertIcon}>
                {i18n.t("title.patientatrisk") + (cardContent.isAtRisk ? i18n.t("title.yes")  : i18n.t("title.no"))}
              </h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{i18n.t("label.reasondiagnosis")}</p>
            <p>
              {i18n.t("label.patientimmunossupressivetherapy") + " (code sct 86553008) : " +
                (cardContent.isAtRisk ? i18n.t("title.yes") : i18n.t("title.no"))}
            </p>
            <p>
              {i18n.t("label.patientEBVinfection") +" (code sct 240530001) : " +
                (cardContent.hasEBV ? i18n.t("title.yes") : i18n.t("title.no"))}
            </p>
          </Modal.Body>
        </Modal>
        <Modal
          show={annuaireShow}
          onHide={handleCloseAnnuaire}
          hide
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h2 className={styles.flexStartAlertIcon}>
                {i18n.t("title.healthdirectory")}
              </h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SearchableTable
              searchCriteriaProps={{
                title: i18n.t("title.searchcriteria"),
                primaryButtonLabel: i18n.t("button.search"),
                secondaryButtonLabel: i18n.t("button.reset"),
                language: i18n.t,
                searchResultModifier: {
                  _elements: "id,extension",
                  _sort: "-_lastUpdated",
                },
                criteria: [
                  {
                    inputProps: {
                      label: i18n.t("label.name"),
                      type: "text",
                    },
                    searchParamsName: "name:contains",
                  },
                ],
              }}
              paginationTableProps={{
                columns: [
                  {
                    header: "ID",
                    dataField: "id",
                    width: "25%",
                  },
                  {
                    header: i18n.t("label.name"),
                    dataField: "Name",
                    width: "40%",
                  },
                ],
                action: [],
                mapResourceToData: (resource: any) => {
                  return {
                    id: resource.id,
                    Name:
                      resource.extension[0].valueHumanName.given[0] +
                      " " +
                      resource.extension[0].valueHumanName.family,
                  };
                },
                searchProps: {
                  serverUrl: process.env.REACT_APP_FHIR_URL ?? "fhir",
                  resourceType: "PractitionerRole",
                },
                onError: () => "",
              }}
            />
          </Modal.Body>
        </Modal>

        <div className="section">
          <div className="displayFlexCenter">
            <Title level={1} content={i18n.t("title.ovhdemonstrator")} />
          </div>
          <br/>
          <br/>

          <Row>
            <Col xs={4}>
              <div className="displayFlexCenter">
                <Badge bg="success">
                  <Title level={2} content={"Patients : " + patientCount} />
                </Badge>
              </div>
            </Col>
            <Col xs={4}>
              <div className="displayFlexCenter">
                <Badge bg="success">
                  <Title
                    level={2}
                    content={
                      i18n.t("title.nonoppositionresearch") + opposedCount
                    }
                  />
                </Badge>
              </div>
            </Col>
            <Col xs={4}>
              <div className="displayFlexCenter">
                <Badge bg="success">
                  <Title
                    level={2}
                    content={i18n.t("title.patientrisk") + sickCount}
                  />
                </Badge>
              </div>
            </Col>
          </Row>

          <br/><br/><br/>

          {/* INPUT */}
          <Row>
            <Col xs={4}>
              <Card className={styles.card}>
                <Card.Header className="flexWrapSpaceBetween">
                  <Title level={2} content={i18n.t("title.dataimport")} />
                  <div
                    className={[styles.buttonPadding, "iconCenter"].join(" ")}
                  ></div>
                </Card.Header>

                <Card.Body className="cardBody">
                  <Row>
                    <Col xs={10}>
                      <Button
                        variant="primary"
                        className="fullButton"
                        onClick={() => importConsent()}
                      >
                        {i18n.t("button.importconsentment")}{" "}
                      </Button>
                    </Col>
                    <Col xs={2}>
                      <FontAwesomeIcon
                        icon={faQuestionCircle}
                        size="2x"
                        className="actionIcon"
                        onClick={() =>
                          window.open(
                            "https://janus.ovh.fyrstain.com/StructureMap/StructureMap-Consent-JSON",
                            "_blank"
                          )
                        }
                      />
                    </Col>
                  </Row>
                  <br></br>

                  <Row>
                    <Col xs={10}>
                      <Button
                        variant="primary"
                        className="fullButton"
                        onClick={() => importPatientData()}
                      >
                        {" "}
                        {i18n.t("button.importpatientfile")}
                      </Button>
                    </Col>
                    <Col xs={2}>
                      <FontAwesomeIcon
                        icon={faQuestionCircle}
                        size="2x"
                        className="actionIcon"
                        onClick={() =>
                          window.open(
                            "https://janus.ovh.fyrstain.com/StructureMap/StructureMap-FicheSante-FHIR",
                            "_blank"
                          )
                        }
                      />
                    </Col>
                  </Row>
                  <br></br>
                  <Row>
                    <Col xs={10}>
                      <Button
                        variant="primary"
                        className="fullButton"
                        onClick={() => importPatientAtRiskData()}
                      >
                        {i18n.t("button.importpatientfilerisk")}
                      </Button>
                    </Col>
                    <Col xs={2}>
                      <FontAwesomeIcon
                        icon={faQuestionCircle}
                        size="2x"
                        className="actionIcon"
                        onClick={() =>
                          window.open(
                            "https://janus.ovh.fyrstain.com/StructureMap/StructureMap-FicheSante-FHIR",
                            "_blank"
                          )
                        }
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={4}>
              <Card className={styles.card}>
                <Card.Header className="flexWrapSpaceBetween">
                  <Title level={2} content={i18n.t("title.exploitationdata")} />
                  <div
                    className={[styles.buttonPadding, "iconCenter"].join(" ")}
                  ></div>
                </Card.Header>

                <Card.Body className="cardBody">
                  <Row>
                    <Col xs={10}>
                      <Button
                        variant="primary"
                        className="fullButton"
                        onClick={() => setModalShowBottom(true)}
                      >
                        {i18n.t("button.diagnosisCQL")}
                      </Button>
                    </Col>
                    <Col xs={2}>
                      <FontAwesomeIcon
                        icon={faQuestionCircle}
                        size="2x"
                        className="actionIcon"
                        onClick={() =>
                          window.open(
                            "https://polus.ovh.fyrstain.com/cql/ImmunoAtRisk",
                            "_blank"
                          )
                        }
                      />
                    </Col>
                  </Row>
                  <br></br>

                  <Row>
                    <Col xs={10}>
                      <Button
                        variant="primary"
                        className="fullButton"
                        onClick={() => {
                          setDisableInput(false);
                          setTargetSystemId("https://www.ghdc.be");
                          setTargetUrl(process.env.REACT_APP_FHIR_URL ?? "");
                          setModalShowRight(true);
                        }}
                      >
                        {i18n.t("button.exportpatientsummary")}
                      </Button>
                    </Col>
                  </Row>
                  <br></br>

                  <Row>
                    <Col xs={10}>
                      <Button
                        variant="primary"
                        className="fullButton"
                        onClick={() => exportResearch()}
                      >
                        {i18n.t("button.exportresearsh")}
                      </Button>
                    </Col>
                    <Col xs={2}>
                      <FontAwesomeIcon
                        icon={faQuestionCircle}
                        size="2x"
                        className="actionIcon"
                        onClick={() =>
                          window.open(
                            "https://demonstrator.ovh.fyrstain.com/iris/csp/sys/exp/%25CSP.UI.Portal.SQL.Home.zen?$NAMESPACE=FHIRSERVER",
                            "_blank"
                          )
                        }
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={4}>
              <Card className={styles.card}>
                <Card.Header className="flexWrapSpaceBetween">
                  <Title level={2} content={i18n.t("title.otherfeatures")} />
                  <div
                    className={[styles.buttonPadding, "iconCenter"].join(" ")}
                  ></div>
                </Card.Header>

                <Card.Body className="cardBody">
                  <Row>
                    <Col xs={10}>
                      <Button
                        variant="primary"
                        className="fullButton"
                        onClick={() =>
                          window.open(
                            "https://jupiter.ovh.fyrstain.com/Statistics",
                            "_blank"
                          )
                        }
                      >
                        {i18n.t("button.statistics")}
                      </Button>
                    </Col>
                  </Row>
                  <br></br>

                  <Row>
                    <Col xs={10}>
                      <Button
                        variant="primary"
                        className="fullButton"
                        onClick={() => {
                          setDisableInput(true);
                          setTargetSystemId(
                            "urn:oid:2.16.840.1.113883.2.4.6.3"
                          );
                          setTargetUrl("https://hapi.fhir.org/baseR4");
                          setModalShowRight(true);
                        }}
                      >
                        {i18n.t("button.importpatientsummary")}
                      </Button>
                    </Col>
                  </Row>
                  <br></br>

                  <Row>
                    <Col xs={10}>
                      <Button
                        variant="primary"
                        className="fullButton"
                        onClick={() =>
                          window.open(
                            "https://atlas.ovh.fyrstain.com/",
                            "_blank"
                          )
                        }
                      >
                        {i18n.t("button.positiongps")}
                      </Button>
                    </Col>
                  </Row>
                  <br></br>
                  <Row>
                    <Col xs={10}>
                      <Button
                        variant="primary"
                        className="fullButton"
                        onClick={() => setAnnuaireShow(true)}
                      >
                        {i18n.t("button.healthdirectory")}
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    </JupiterPage>
  );
};

export default Home;
